import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import colors from "../../../styles/colors"
import fonts from "../../../styles/fonts"
import breakpoints from "../../../styles/breakpoints"
import { Row, Col } from "styled-bootstrap-grid"

const SectionContainer = styled.div`
  @media(max-width: ${breakpoints.md}){
    display: ${(props) => props.desktopVersion && "none"};
  }
`

const Background = styled.div`
  background: ${colors.vanilla};
  padding: 60px 30px 80px;

  @media (min-width: ${breakpoints.sm}) {
    padding: 60px 60px 80px;
  }
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
  }
`

const Instruction = styled.div`
  ${fonts.garamondRegular};
  margin-top: 40px;
  padding: 30px;
  border: 1px solid ${colors.orange};
  text-align: center;
  font-size: 26px;
  line-height: 1.2;
`

const StyledCol = styled(Col)`
  @media (min-width: ${breakpoints.md}) {
    padding: 60px;
    min-height: 600px;
    display: flex;
    align-items: center;
  }
`

const TitleSection = styled.div`
  margin-bottom: 40px;
  text-align: center;
  display: ${(props) => (props.mobile ? "block" : "none")};
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 50px;
    display: ${(props) => (props.desktop ? "block" : "none")};
  }
`

const Title = styled.h2`
  ${fonts.garamondRegular};
  font-size: 40px;
  letter-spacing: normal;
  line-height: 1.15;
  @media (min-width: ${breakpoints.md}) {
    font-size: 48px;
    letter-spacing: -0.4px;
  }
`

const StyledRow = styled(Row)`
  max-width: 480px;
  margin: auto;
  @media (min-width: ${breakpoints.md}) {
    max-width: unset;
  }
`

const StyledImage = styled.img`
  @media (min-width: ${breakpoints.md}) {
    height: auto;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const MobileImage = styled(StyledImage)`
  display: block !important;
  @media (min-width: ${breakpoints.md}) {
    display: none !important;
  }
`

const DesktopImage = styled(StyledImage)`
  display: none !important;
  @media (min-width: ${breakpoints.md}) {
    display: block !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
`

const Content = styled.div`
  @media (min-width: ${breakpoints.md}) {
    max-width: 480px;
    margin: auto;
  }
`

const HowToSection = (props) => {
  const {
    copy,
    title,
    how_to_image: image,
    how_to_image_mobile: imageMobile,
    desktop
  } = props

  return (
    <SectionContainer desktopVersion={desktop}>
      <Background>
        <StyledRow>
          <Col hiddenMdUp={true}>
            <TitleSection mobile>
              <Title>{title}</Title>
            </TitleSection>
          </Col>
          <Col md={6} mdOrder={"last"}>
            <MobileImage
              src={imageMobile ? imageMobile.file.url : image.file.url}
              alt={imageMobile ? imageMobile.title : image.title}
            />
            <ImageContainer>
              <DesktopImage src={image.file.url} alt={image.title} />
            </ImageContainer>
          </Col>
          <StyledCol md={6} mdAlignSelf={"center"}>
            <Content>
              <TitleSection desktop>
                <Title>{title}</Title>
              </TitleSection>
              <Instruction>{copy}</Instruction>
            </Content>
          </StyledCol>
        </StyledRow>
      </Background>
    </SectionContainer>
  )
}

export default HowToSection
